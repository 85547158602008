import React from 'react';
import Logo from "../components/images/logo.png";
import "./Chargement.scss";

export default function Chargement() {
    return (
        <div className='corps'>
            <img
                className="image-logo"
                src={Logo}
                alt="logo"
            />
        </div>
    )
}
