import React, { lazy, Suspense } from "react";
import "./Home.scss";
import Carousel from "../../../components/Carousel";
const Success = lazy(() => import("./Success & Impact/Success"));
const Intervention = lazy(() => import("./Intervention/Intervention"));
const AllPosts = lazy(() => import("./News/Allposts"));

function Home() {
  return (
    <div className="background">
      <Carousel />
      <Suspense fallback={<div>Chargement...</div>}>
        <AllPosts />
        <Intervention />
        <Success />
      </Suspense>
    </div>
  );
}

export default Home;
