import "./Carousel.scss";
import myImage1 from "./images/1.jpg";
import myImage2 from "./images/2.jpg";
import myImage3 from "./images/ALOE.png";
import monthlynew from "./images/mars_24.png";
import myImage5 from "./images/avgpic.jpg";
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation, Pagination]);

const Carousel = () => {
  return (
    <Swiper
      className="swipper"
      spaceBetween={-1}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      loop={true}
      autoplay={{ delay: 10000, disableOnInteraction: false }}>

        <SwiperSlide>
        <img
          className="imageCarousel"
          src={myImage3}
          alt="slide4"
        />
        <div className="text">
        <a
          href="https://alliancevoaharygasy.org/aloe/"
          className="my-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span><i class="fa-solid fa-download fa-lg"></i>  Téléchargez ALOE</span>
        </a>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <img
          className="imageCarousel"
          src={monthlynew}
          alt="slide4"
        />
        <div className="text">
        🌳💚 𝐒𝐚𝐮𝐯𝐨𝐧𝐬 𝐧𝐨𝐬 𝐅𝐨𝐫𝐞̂𝐭𝐬, 𝐀𝐠𝐢𝐬𝐬𝐨𝐧𝐬 𝐩𝐨𝐮𝐫 𝐮𝐧 𝐀𝐯𝐞𝐧𝐢𝐫 𝐑𝐞𝐬𝐩𝐨𝐧𝐬𝐚𝐛𝐥𝐞 ! 🌍
        </div>
      </SwiperSlide>
      <SwiperSlide className="swipperSlide">
        <img
          className="imageCarousel"
          src={myImage1}
          alt="slide1"
        />
        <div className="text">
          <i><q> Aza ny hafohezan'ny andro no alaina, fa ny halavan'ny taona heverina </q> </i>
          <br />
          <br /><q> Ne considérez pas la brièveté des jours, mais pensez à la
            longueur de l'année  </q>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img
          className="imageCarousel"
          src={myImage2}
          alt="slide2"
        />
        <div className="text">
          <i>Alliance Voahary Gasy : </i> <br /> Une plateforme des
          organisations de la Sociéte Civile oeuvrant pour l'environnement.
          Oeuvrant pour l'instauration de la bonne gouvernance environnementale
          à Madagascar qui compte 30 organisations membres qui se sont regroupés pour une
          dialogue politique favorable à une gestion rationnelle des ressources
          naturelles.
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <img
          className="imageCarousel"
          src={myImage5}
          alt="slide3"
        />
        <div className="text">
          <i>Valeur </i> <br /> <i class="fa-regular fa-circle-check"></i> La
          bonne gouvernance <br />
          <i class="fa-regular fa-circle-check"></i> La cohérence avec les
          valeurs et réalités Malagasy <br />
          <i class="fa-regular fa-circle-check"></i> La reconnaissance, le
          respect et la valorisation du capital naturel et du bien Collectif{" "}
          <br />
          <i class="fa-regular fa-circle-check"></i> La responsabilité
          (Intégrité, engagement, persévérance et éthique professionnel).{" "}
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default Carousel;
