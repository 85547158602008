import './App.css';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home/Home';
import ScrollToTop from './components/ScrollToTop';
import Chargement from './components/Chargement';

const Navbar = lazy(() => import("./components/Navbar"));
const Stories = lazy(() => import("./components/pages/Stories"));
const Strategy = lazy(() => import("./components/pages/Strategy"));
const Gouvernance = lazy(() => import("./components/pages/Gouvernance"));
const Intervention = lazy(() => import("./components/pages/Intervention/Intervention"));
const Theme = lazy(() => import("./components/pages/Theme"));
const Project = lazy(() => import("./components/pages/Project"));
const Job = lazy(() => import("./components/pages/job"));
const Rapport = lazy(() => import("./components/pages/Rapport"));
const Formation = lazy(() => import("./components/pages/Formation"));
const Docs = lazy(() => import("./components/pages/Docs"));
const Actualities = lazy(() => import("./components/pages/Actualities"));
const Pictures = lazy(() => import("./components/pages/Pictures"));
const Footer = lazy(() => import("./components/Footer"));
const Contact = lazy(() => import("./components/Contact"));
const About = lazy(() => import("./components/About"));
const Don = lazy(() => import("./components/Don"));
const Membres = lazy(() => import("./components/pages/Home/Membres"));
const ToutArticle = lazy(() => import("./components/ToutArticle"));
const Allintervention = lazy(() => import("./components/pages/Intervention/Allintervention"));
const JusticeEnvironnementale = lazy(() => import("./components/pages/Intervention/JusticeEnvironnementale"));
const AllPosts = lazy(() => import("./components/pages/Home/News/Allposts"));
const OnePost = lazy(() => import("./components/OnePost"));
const VCP = lazy(() => import("./components/pages/Intervention/VCP"));
const CapacityRenforcement = lazy(() => import("./components/pages/Intervention/CapacityRenforcement"));
const Networking = lazy(() => import("./components/pages/Intervention/Networking"));
const Communication = lazy(() => import("./components/pages/Intervention/Communication"));
const AVGmembres = lazy(() => import("./components/pages/AVGmembres"));
const AmiAVGform = lazy(() => import("./components/pages/AmiAVGform"));
const DevenirMembreAVG = lazy(() => import("./components/pages/DevenirMembreAVG"));
const NewsletterSignup = lazy(() => import("./components/pages/NewsletterSignup"));
const Resultats = lazy(() => import("./components/pages/Resultats"));
const Objectifs = lazy(() => import("./components/pages/Objectifs"));

function App() {

  return (<>
    <BrowserRouter>
      <ScrollToTop />
      <Suspense fallback={<Chargement />}>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/stories' element={<Stories />} />
          <Route path='/objectifs' element={<Objectifs />} />
          <Route path='/strategies' element={<Strategy />} />
          <Route path='/about' element={<About />} />
          <Route path='/AVGmembres' element={<AVGmembres />} />
          <Route path='/membres' element={<Membres />} />
          <Route path='/admin' element={<Gouvernance />} />
          <Route path='/intervention' element={<Intervention />} />
          <Route path='/theme' element={<Theme />} />
          <Route path='/project' element={<Project />} />
          <Route path='/job' element={<Job />} />
          <Route path='/rapport' element={<Rapport />} />
          <Route path='/formation' element={<Formation />} />
          <Route path='/docs' element={<Docs />} />
          <Route path='/actualities' element={<Actualities />} />
          <Route path='/newletter' element={<NewsletterSignup />} />
          <Route path='/pictures' element={<Pictures />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/don' element={<Don />} />
          <Route path='/tousArticles' element={<ToutArticle />} />
          <Route path="/blog/:slug" exact element={<OnePost />} />
          <Route exact path="/blog" element={<AllPosts />} />
          <Route exact path="/justiceEnvironnementale" element={<JusticeEnvironnementale />} />
          <Route exact path="/veilleCampagnePlaidoyer" element={<VCP />} />
          <Route exact path="/capacityRenforcement" element={<CapacityRenforcement />} />
          <Route exact path="/communication" element={<Communication />} />
          <Route exact path="/networking" element={<Networking />} />
          <Route exact path="/allintervention" element={<Allintervention />} />
          <Route exact path="/amiAVG" element={<AmiAVGform />} />
          <Route exact path="/devenirMembreAVG" element={<DevenirMembreAVG />} />
          <Route path="/results" element={<Resultats />} />

        </Routes>
        <Footer />
      </Suspense>
    </BrowserRouter>
  </>
  );
}

export default App;