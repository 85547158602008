import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'swiper/swiper-bundle.css';
//import 'swiper/components/navigation/navigation.min.css';

import 'swiper/swiper-element.min.css';
//import 'swiper/element/swiper-element-bundle.js';
//import 'swiper/element/swiper-element.js';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper/core';
import './index.css';
SwiperCore.use([Autoplay, Navigation, Pagination]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />    
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();